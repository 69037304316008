import { Component, inject } from '@angular/core';
import { FeedbackWindowService } from '../feedback/feedback-window/feedback-window.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  private feedbackWindowService = inject(FeedbackWindowService);
  isFeedbackOpen = this.feedbackWindowService.isFeedbackWindowOpen;
}
