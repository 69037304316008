import { Injectable } from '@angular/core';
import { Config } from '../constants/constants';
import { Permission } from '../interfaces/Admin/Role/RoleDetailsByRoleIdResponse';
import { LocalPermissions } from '../interfaces/LocalPermissions';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  getPermissionByName(permissions: Permission[], title: string) {
    return permissions.find((per: Permission) => per.featureName === title) || this.getDefaultPermissions();
  }

  getPermissionByFeatureID(permissions: Permission[], featureID: number) {
    return permissions.find((per: Permission) => per.featureId === featureID) || this.getDefaultPermissions();
  }

  getDefaultPermissions(): LocalPermissions {
    return {
      canView: false,
      canCreateUpdate: false,
      canDelete: false,
      canDownload: false,
    };
  }

  checkIfFileSelectionLimitExceeds(length: number, checkForEqual: boolean = false) {
    if (checkForEqual) {
      return length >= Config.numberOfFilesAllowedWorkRequest;
    }
    return length > Config.numberOfFilesAllowedWorkRequest;
  }

  checkIfFileSizeExceeds(fileSize: number) {
    return fileSize > 25 * 2 ** 20; // 25 MB
  }

  checkFileExtensions(fileName: string) {
    return Config.fileUploadFormats.split(',').includes(fileName.substring(fileName.lastIndexOf('.')));
  }
}
