export interface ApiResponse<T> {
  result: T;
  status: boolean;
  statusCode: number;
  message: string;
}

export interface SolicitorsDashboardModel {
  firstName: string;
  weeklyWorkPattern: number;
  showBonus: boolean;
  showUtilisationOnly: boolean;
  daily: DailyUtilisation[];
  monthly: MonthlyUtilisation;

  month: number;
  year: number;
}

export interface DailyUtilisation {
  billableHours: number;
  nonBillableHours: number;
  utilisation: number;
  bonusPercentage: number;
  date: string;
  workPattern: number;
  eightyPercentTargetHours: number;
}

export interface MonthlyUtilisation {
  month: number;
  utilisation: number;
  bonusPercentage: number;
  totalMonthlyWorkingHours: number;
}

export const EMPTY_SOLICITORS_DASHBOARD_API_RESPONSE: ApiResponse<SolicitorsDashboardModel[]> = {
  message: '',
  status: true,
  statusCode: 0,
  result: [],
};
