/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Permissions } from 'src/app/shared/enums/enums';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { FeatureListByRoleTypeId } from '../../shared/interfaces/Admin/Role/FeatureListByRoleTypeId';
import {
  Permission,
  RoleDetailsByRoleIdResponse,
} from '../../shared/interfaces/Admin/Role/RoleDetailsByRoleIdResponse';
import { User, UserDetailsByUserIdResponse } from '../../shared/interfaces/Auth/UserDetailsByUserIdResponse';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { AuthService, LoginService, RoleService, UserService } from '../../shared/services';
import { FeedbackWindowService } from '../feedback/feedback-window/feedback-window.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit, OnDestroy {
  permissions: Permission[];
  menuItems: any = [];
  tempmenuItems: any;
  roleType: string;
  firstRoute: string | null;
  currentRoleId: number;
  isMenuLoading: boolean = true;
  tempPermission: boolean = false;
  routeToBeLoaded: string = '';
  timeSheetReportParams: { cDate: string; mID: string; cID: string };
  matterParams: { cID: string; mID: string };
  clientParams: { cID: string };
  businessReportingParams: { rID: string };
  currentUserId: number;
  currentUserDetails: User;
  profileImageURL: string | undefined = '';
  nonUser: string = 'Non-User';
  isLoading: boolean = false;
  excludeMenuItems: string[] = ['Logout', 'editprofile', 'Hjhours', 'BillableHour'];
  isFeedbackCanCreateUpdate = false;
  menuCollapsed = signal(false);
  currentApplicationVersion = environment.appVersion;
  onDestroy$: Subject<void> = new Subject();
  featureListResult: FeatureListByRoleTypeId;

  constructor(
    public menu: MenuItems,
    private _authService: AuthService,
    private router: Router,
    private _roleService: RoleService,
    private _userService: UserService,
    private _loginService: LoginService,
    private _modalService: NgbModal,
    private toastrService: ToastrService,
    private _activatedRoute: ActivatedRoute,
    private _utils: UtilsService,
    private feedbackWindowService: FeedbackWindowService,
  ) {
    const data = this._authService.get('encryptedValue');
    const userInfo = this._authService.getUserInfo();
    if (userInfo) {
      this.currentUserId = userInfo.userId;
      this.currentRoleId = userInfo.roleId;
      this.roleType = userInfo.roleTypeName;
      this.getUserDetails(this.currentUserId);
    } else if (!data) {
      localStorage.clear();
      this.router.navigate(['login']);
    }
    this.getUserProfile();
    this.getPermissionList(() => {
      this.permissionCheck(() => {
        this.loopPermission(() => {
          this.menuItems = this.tempmenuItems;
          this.isMenuLoading = false;
          if (this.tempPermission) {
            if (
              this.timeSheetReportParams?.cDate ||
              this.timeSheetReportParams?.cID ||
              this.timeSheetReportParams?.mID
            ) {
              this.router.navigate([this.routeToBeLoaded], { queryParams: this.timeSheetReportParams });
            } else if (this.matterParams?.cID || this.matterParams?.mID) {
              this.router.navigate([this.routeToBeLoaded], { queryParams: this.matterParams });
            } else if (this.clientParams?.cID) {
              this.router.navigate([this.routeToBeLoaded], { queryParams: this.clientParams });
            } else if (this.businessReportingParams?.rID) {
              this.router.navigate([this.routeToBeLoaded], { queryParams: this.businessReportingParams });
            } else {
              this.router.navigate([this.routeToBeLoaded]);
            }
          } else if (this.firstRoute) {
            this.router.navigate([this.firstRoute], { replaceUrl: true });
          } else this.router.navigate(['admin/not-found']);
        });
      });
    });
  }

  //collapse menu
  toggleMenu(newState: boolean): void {
    this.menuCollapsed.set(newState);
    // Get the state of the sidemenu using localStorage
    localStorage.setItem('sidemenuState', newState.toString());
  }

  ngOnInit() {
    const sidemenuState = localStorage.getItem('sidemenuState');
    if (sidemenuState !== null) {
      this.toggleMenu(sidemenuState === 'true');
    }

    this._loginService.profilePictureObservable.pipe(takeUntil(this.onDestroy$)).subscribe((d) => {
      this.profileImageURL = d;
    });
    this._loginService.userNameObservable.pipe(takeUntil(this.onDestroy$)).subscribe((d) => {
      this.currentUserDetails && (this.currentUserDetails.fullName = d);
    });
  }

  getUserDetails(userId: number) {
    this._loginService.getUserByID(userId).subscribe({
      next: this.handleGetUserByIdSuccess.bind(this),
      error: this.handleGetUserByIdFailure.bind(this),
    });
  }

  handleGetUserByIdSuccess(result: UserDetailsByUserIdResponse) {
    this.currentUserDetails = result.result;
    this._loginService.setPracticeAreaData(result.result.practiceArea);
  }

  handleGetUserByIdFailure(result: HttpErrorResponse) {
    this.toastrService.error(result?.error?.message);
  }

  openProfileEdit() {
    this.router.navigate(['admin', 'editprofile']);
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.profileImageURL = reader.result?.toString();
        if (this.profileImageURL) this._loginService.setUpdatedData('userProfile', this.profileImageURL);
      },
      false,
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getUserProfile() {
    this._userService.getUserProfileImage(this.currentUserId).subscribe({
      next: this.handleGetUserProfileImageSuccess.bind(this),
      error: this.handleGetUserProfileImageFailure.bind(this),
    });
  }

  handleGetUserProfileImageSuccess(result: Blob) {
    this.createImageFromBlob(result);
  }

  handleGetUserProfileImageFailure(result: HttpErrorResponse) {
    this.toastrService.error(result?.error?.message);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPermissionList(callback: any) {
    this._roleService.getRolePermissionByRoleId(this.currentRoleId).subscribe({
      next: this.handleGetRolePermissionByRoleIdSuccess.bind(this, callback),
      error: () => callback(),
    });
  }

  handleGetRolePermissionByRoleIdSuccess(callback: any, result: RoleDetailsByRoleIdResponse) {
    this.permissions = result.result.permission;
    this.isFeedbackCanCreateUpdate = this._utils.getPermissionByName(
      this.permissions,
      Permissions.Feedback,
    ).canCreateUpdate;
    this._roleService.setUpdatedData(this.permissions);
    callback?.();
  }

  permissionCheck(callback: any) {
    this.tempmenuItems = this.menu.getMenuitem();
    callback();
  }

  loopPermission(callback: any) {
    this.tempmenuItems.forEach((row: any, index: number) => {
      !this.excludeMenuItems.includes(row.label) && (row.roles = []);
      this.checkRolePermission(row);
      if (index === this.tempmenuItems.length - 1) {
        callback();
      }
    });
  }

  setExtraRouteParams(route: string, menu: any) {
    this._activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.tempPermission = true;
      this.routeToBeLoaded = menu.route;
      switch (route) {
        case 'timesheetreport':
          this.timeSheetReportParams = params as { cDate: string; mID: string; cID: string };
          break;

        case 'matter':
          this.matterParams = params as { cID: string; mID: string };
          break;

        case 'clients':
          this.clientParams = params as { cID: string };
          break;

        case '/reports':
          this.businessReportingParams = params as { rID: string };
          break;
      }
    });
  }

  checkRolePermission(menu: any, flags: boolean[] = []) {
    if (!menu.children && this.permissions) {
      const temp = this.permissions.find((per) => per.featureName === menu.label && per.canView);
      if (temp) {
        this.checkRouteAndSetExtraParams(menu);
        if (!this.firstRoute && temp?.canView) this.firstRoute = menu.route;
        menu.roles.push(this.roleType);
        flags?.push(true);
        return;
      } else if (menu.roles) {
        menu.roles.push(this.nonUser);
      }
      flags?.push(false);
      return;
    } else if (menu?.children?.length > 0) {
      menu.children.forEach((child: any) => {
        this.checkRolePermission(child, flags);
      });
    }
    !this.excludeMenuItems.includes(menu.label) && menu?.roles.push(this.nonUser);
    flags?.includes(true) && menu?.roles.push(this.roleType);
  }

  checkRouteAndSetExtraParams(menu: any) {
    if (this.router.url.search('admin') && this.router.url.localeCompare(menu.route) === 0) {
      this.tempPermission = true;
      this.routeToBeLoaded = menu.route;
    } else if (
      this.router.url.includes('editprofile') ||
      this.router.url.includes('hjhours') ||
      this.router.url.includes('billablehour') ||
      this.router.url.includes('temptimehour') ||
      this.router.url.includes('chat/metrics') ||
      this.router.url.includes('chat/assistant') ||
      this.router.url.includes('client-rates') ||
      this.router.url.includes('billing/runs')
    ) {
      this.tempPermission = true;
      this.routeToBeLoaded = this.router.url;
    } else if (this.router.url.includes('timesheetreport') && menu.route.includes('timesheetreport')) {
      this.setExtraRouteParams('timesheetreport', menu);
    } else if (this.router.url.includes('matter') && menu.route.includes('matter')) {
      this.setExtraRouteParams('matter', menu);
    } else if (this.router.url.includes('clients') && menu.route.includes('clients')) {
      this.setExtraRouteParams('clients', menu);
    } else if (this.router.url.includes('/reports') && menu.route.includes('/reports')) {
      this.setExtraRouteParams('/reports', menu);
    }
  }

  //click feedback button -> call this function to show feedback window
  openFeedbackWindow() {
    this.feedbackWindowService.openFeedbackWindow();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
