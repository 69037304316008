import { ClipboardModule } from '@angular/cdk/clipboard';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { AddUpdateWorkRequestModalComponent } from './components/add-update-work-request-modal/add-update-work-request-modal.component';
import { AvailabilityModalComponent } from './components/availability-modal/availability-modal.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { DayChangeModalComponent } from './components/day-change-modal/day-change-modal.component';
import { DeleteDataModalComponent } from './components/delete-data-modal/delete-data-modal.component';
import { DocumentsUploadInProgressComponent } from './components/documents-upload-in-progress/documents-upload-in-progress.component';
import { EditMatterBudgetPopupComponent } from './components/edit-matter-budget-popup/edit-matter-budget-popup.component';
import { EditTimerModalComponent } from './components/edit-timer-modal/edit-timer-modal.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { InsertBudgetNotesComponent } from './components/insert-budget-notes/insert-budget-notes.component';
import { InvoicesNotSentPopupComponent } from './components/invoices-not-sent-popup/invoices-not-sent-popup.component';
import { LongRunningTimerModalComponent } from './components/long-running-timer-modal/long-running-timer-modal.component';
import { ManualRequestModalComponent } from './components/manual-request-modal/manual-request-modal.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReportsHelpModalComponent } from './components/reports-help-modal/reports-help-modal.component';
import { ResendInvoiceInformationModalComponent } from './components/resend-invoice-information-modal/resend-invoice-information-modal.component';
import { TimerButtonsComponent } from './components/timer-buttons/timer-buttons.component';
import { TimerPopUpModalComponent } from './components/timer-pop-up-modal/timer-pop-up-modal.component';
import { VerticalNavigationComponent } from './components/vertical-navigation/vertical-navigation.component';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { MaterialModule } from './material/material.module';
import { ClientMenuItems } from './menu-items/client-menu-items';
import { MenuItems } from './menu-items/menu-items';
import { DecimalPlacesPipe } from './pipes/decimal-places.pipe';
import { IsTimerAvailablePipe } from './pipes/is-timer-available.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { SysCurrencyPipe } from './pipes/sys-currency.pipe';

@NgModule({
  declarations: [
    DocumentsUploadInProgressComponent,
    DeleteDataModalComponent,
    InvoicesNotSentPopupComponent,
    EditMatterBudgetPopupComponent,
    IsTimerAvailablePipe,
    SysCurrencyPipe,
    SafeUrlPipe,
    ReportsHelpModalComponent,
    ConfirmationModalComponent,
    VerticalNavigationComponent,
    InsertBudgetNotesComponent,
    DecimalPlacesPipe,
    SafeHtmlPipe,
    SanitizePipe,
    CustomTooltipDirective,
    CustomTooltipComponent,
    LongRunningTimerModalComponent,
    ForgotPasswordComponent,
    PageNotFoundComponent,
    NotFoundComponent,
    ResendInvoiceInformationModalComponent,
    AvailabilityModalComponent,
    TimerPopUpModalComponent,
    TimerButtonsComponent,
    EditTimerModalComponent,
    DayChangeModalComponent,
    ManualRequestModalComponent,
    AddUpdateWorkRequestModalComponent,
    OtpVerificationComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    RouterModule,
    AngularSvgIconModule.forRoot(),
  ],
  exports: [
    DocumentsUploadInProgressComponent,
    DeleteDataModalComponent,
    InvoicesNotSentPopupComponent,
    EditMatterBudgetPopupComponent,
    IsTimerAvailablePipe,
    SysCurrencyPipe,
    SafeUrlPipe,
    ReportsHelpModalComponent,
    ConfirmationModalComponent,
    VerticalNavigationComponent,
    InsertBudgetNotesComponent,
    ForgotPasswordComponent,
    ResendInvoiceInformationModalComponent,
    DecimalPlacesPipe,
    SafeHtmlPipe,
    SanitizePipe,
    CustomTooltipDirective,
    CustomTooltipComponent,
    LongRunningTimerModalComponent,
    ForgotPasswordComponent,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    NgxTrimDirectiveModule,
    CdkScrollableModule,
    ClipboardModule,
    TimerButtonsComponent,
  ],
  providers: [
    MenuItems,
    ClientMenuItems,
    IsTimerAvailablePipe,
    SysCurrencyPipe,
    SafeUrlPipe,
    DecimalPlacesPipe,
    SafeHtmlPipe,
    SanitizePipe,
    CustomTooltipDirective,
  ],
})
export class SharedModule {}
