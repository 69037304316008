import { inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { APIUrls } from '../enums/apiUrls';
import { ApiResponse } from '../interfaces/api-response.model';
import { SolicitorsDashboardModel } from '../interfaces/Solicitors-Dashboard/SolicitorsDashboardResponse';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SolicitorsDashboardService {
  apiService = inject(ApiService);

  getSolicitorsDashboard(id: number, year: number, month: number): Observable<ApiResponse<SolicitorsDashboardModel[]>> {
    return this.apiService
      .get<ApiResponse<SolicitorsDashboardModel[]>>(`${APIUrls.SolicitorsDashboard}/${id}?year=${year}&month=${month}`)
      .pipe(
        catchError((error) => {
          console.error('API error:', error);
          return throwError(() => new Error('API call failed'));
        }),
      );
  }
}
